<template>
  <div>
    <k-crud-layout :with-search="false">
      <template #header>
        <router-link :to="{name: 'approvals.index'}" class="secondary--text">
          {{ $t('approvals.pageTitle') }}
        </router-link>
        / {{ $t('approvals.details.pageTitle', [approvalId]) }}
      </template>
      <template #flow-actions>
        <v-btn color="secondary"
               class="mr-2"
               outlined
               tile
               :loading="isPendingSubmit"
               :disabled="currentUserId !== isCurrentUserNextApprover"
               @click="rejectHandler">
          {{ $tc('approvals.actions.reject') }}
        </v-btn>
        <v-btn color="primary"
               class="mx-2"
               :disabled="currentUserId !== isCurrentUserNextApprover"
               depressed
               tile
               :loading="isPendingSubmit"
               @click="approve">
          {{ $tc('approvals.actions.approve') }}
        </v-btn>

        <v-btn class="mx-2" outlined tile @click="isCommentsSidebarOpen = true">
          <v-badge class="mr-4" overlap color="yellow" bordered dark v-if="orderDetails.commentCount">
            <v-icon>$comments</v-icon>
            <template #badge>
              <span class="black--text">{{ orderDetails.commentCount }}</span>
            </template>
          </v-badge>
          {{ $t('orders.details.comments') }}
        </v-btn>
        <OrderCommentsSideBar v-model="isCommentsSidebarOpen" :order-id="$route.params.approvalId"/>
      </template>
      <template #sheet-prepend v-if="orderDetails.isLoaded">
        <v-row justify="end" align-content="center">
          <v-col cols="auto">
            <div class="d-flex box--total">
              <div class="d-inline-flex align-self-center pl-6 pr-8">
                <div class="mt-1 mr-4 font-weight-medium text-h3">{{ $t('shoppingCart.total') }}:</div>
                <i18n-n :tag="false"
                        :value="totalAmount"
                        :format="{ key: 'currency' }"
                        class="d-inline-flex">
                  <template #currency="{currency}">
                    <div class="text-h1 font-weight-medium">{{ currency }}</div>
                  </template>
                  <template #group="{group}">
                    <div class="text-h1 font-weight-medium">{{ group }}</div>
                  </template>
                  <template #integer="{integer}">
                    <div class="text-h1 font-weight-medium">{{ integer }}</div>
                  </template>
                  <template #decimal="{decimal}">
                    <div class="text-h1 font-weight-medium">{{ decimal }}</div>
                  </template>
                  <template #fraction="{fraction}">
                    <div class="text-h3 font-weight-medium mt-1">{{ fraction }}</div>
                  </template>
                </i18n-n>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <template #main-prepend v-if="orderDetails.isLoaded">
        <v-fade-transition>
          <k-display-group language-prefix="approvals.display">
            <v-row>
              <v-col md="4" sm="12">
                <v-sheet class="pa-4 fill-height">
                  <KSubtitle class="pa-0">{{ $t('approvals.details.storeInfo') }}</KSubtitle>
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="6">
                      <k-text-display class="mb-3" field="storeName">
                        {{ orderDetails.store.name }}
                      </k-text-display>
                      <k-text-display class="mb-3" field="phoneNumber">
                        {{ orderDetails.store.phoneNumber }}
                      </k-text-display>
                      <k-text-display class="mb-3" field="dealerLevel">
                        {{ orderDetails.store.dealerLevel }}
                      </k-text-display>
                    </v-col>
                    <v-col cols="6">
                      <k-text-display class="mb-3" field="dealerCode">
                        {{ orderDetails.store.dealerCode }}
                      </k-text-display>
                      <k-text-display class="mb-3" field="branchExtension">
                        {{ orderDetails.store.branchExtension }}
                      </k-text-display>
                      <k-text-display class="mb-3" field="email">
                        {{ orderDetails.store.email }}
                      </k-text-display>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col md="4" sm="12">
                <v-sheet class="pa-4 fill-height">
                  <KSubtitle class="pa-0">{{ $t('approvals.details.addressData') }}</KSubtitle>
                  <v-row class="mt-2" no-gutters>
                    <v-col cols="12" md="6">
                      <v-label>{{ $t('approvals.details.showroomLocation') }}</v-label>
                      <div>{{ orderDetails.store.showroomAddress.addressLine1 }}</div>
                      <div v-if="orderDetails.store.showroomAddress.addressLine2">
                        {{ orderDetails.store.showroomAddress.addressLine2 }}
                      </div>
                      <div>{{ orderDetails.store.showroomAddress.city }}</div>
                      <div>{{ orderDetails.store.showroomAddress.zipCode }}</div>
                      <div class="mb-4">{{ $t(`countries.${orderDetails.store.showroomAddress.countryId}`) }}</div>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-label>{{ $t('approvals.details.shippingAddress') }}</v-label>
                      <div>{{ orderDetails.store.shippingAddress.addressLine1 }}</div>
                      <div v-if="orderDetails.store.shippingAddress.addressLine2">
                        {{ orderDetails.store.shippingAddress.addressLine2 }}
                      </div>
                      <div>{{ orderDetails.store.shippingAddress.city }}</div>
                      <div>{{ orderDetails.store.shippingAddress.zipCode }}</div>
                      <div>{{ $t(`countries.${orderDetails.store.shippingAddress.countryId}`) }}</div>
                    </v-col>
                    <v-col cols="12">
                      <k-text-display width="20px" horizontal label="T">
                        {{ orderDetails.store.phoneNumber }}
                      </k-text-display>
                    </v-col>
                    <v-col cols="12">
                      <k-text-display width="20px" horizontal label="E">
                        {{ orderDetails.store.email }}
                      </k-text-display>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col md="4" sm="12">
                <v-sheet class="pa-4 fill-height">
                  <KSubtitle class="pa-0">{{ $t('approvals.details.approvers') }}</KSubtitle>
                  <v-list subheader class="pa-0" dense>
                    <v-label>{{ $t('approvals.details.approvals') }}</v-label>
                    <v-list-item class="pa-0" v-for="approver in approvers" :key="approver.id">
                      <v-list-item-icon>
                        <v-icon :color="approvalIconColor(approver.isApproved)">
                          {{ approvalIcon(approver.isApproved) }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>{{ approver.user.userName }}
                        <span class="approver-created-at">{{ approver.createdAt }}</span>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-sheet>
              </v-col>
            </v-row>
          </k-display-group>
        </v-fade-transition>
      </template>

      <template #view.list>
        <v-data-table :headers="crudHeaders"
                      :items="orderDetails.orderLines"
                      :loading="isPending"
                      :footer-props="{itemsPerPageOptions: [5,10,15,25],
                   itemsPerPageText: $t('global.$vuetify.dataFooter.itemsPerPageText'),
                   pageText: $t('global.$vuetify.dataFooter.pageText')}">
          <template #item="{item}">
            <tr>
              <td rowspan="2">
                <VImg :src="`${item.image}&type=thumbnail`"
                      :lazy-src="`${item.image}&type=lazy`"
                      height="100px"
                      max-width="100px"
                      contain/>
              </td>
              <td style="border-bottom: 0;">
                {{ item.name }}
              </td>
              <td style="border-bottom: 0;">
                {{ item.sku }}
              </td>

              <td style="border-bottom: 0" class="no-table-separator">
                {{ item.quantity }}
              </td>

              <td style="border-bottom: 0" class="no-table-separator">
                <div class="mb-4">
                  {{ $n(item.price, 'currency') }}
                </div>
              </td>

              <td rowspan="2" style="border-bottom: 0; text-align: right; width: 1%; white-space: nowrap; position: relative">
                <div class="mb-4">
                  {{ $n(item.lineTotal, 'currency') }}
                </div>

                <template v-if="hasAddOns(item)">
                  <div>{{ $t('orders.details.addons') }}:</div>
                  <div class="pl-8">
                    <span>{{ $n(item.addOns.totalPrice, 'currency') }}</span>
                  </div>
                </template>
                <div class="custom-top-border"></div>
              </td>
              <td rowspan="2" style="width: 1%; border-bottom: 0;">
                <div class="crud-actions">
                  <v-btn :disabled="isPendingRemove.includes(item.id)"
                         color="text--secondary"
                         icon
                         v-if="!!$listeners.remove"
                         @click.stop="$emit('remove', item)">
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>

            <tr class="order-addons">
              <td colspan="4" style="border-top: 0;">
                <div v-for="product in item.addOns.products" :key="product.id" class="d-inline ma-3 text-center">
                  <v-badge :value="product.quantity" color="black" :content="product.quantity">
                    <VImg :src="`${product.image}&type=thumbnail`"
                          :lazy-src="`${product.image}&type=lazy`"
                          height="100px"
                          max-width="100px"
                          contain/>
                    {{ product.sku }}
                  </v-badge>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>

    </k-crud-layout>
    <RejectDialog :is-open="isRejectDialogOpen" @close="isRejectDialogOpen = false" @save="reject($event)"/>
  </div>
</template>

<script lang="js">
import { EventBus } from '@/application/EventBus.js';
import { OrderDetail } from '@/application/models/Order.js';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import KSubtitle from '@/components/layout/KSubtitle.vue';
import RejectDialog from '@/modules/approvals/components/rejectDialog.vue';
import { approve, show } from '@/modules/orders/api/order.js';
import OrderCommentsSideBar from '@/modules/orders/components/OrderCommentsSideBar.vue';
import { i18n } from '@/plugins';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'ApprovalDetails',
  components: {
    RejectDialog,
    OrderCommentsSideBar,
    KDisplayGroup,
    KTextDisplay,
    KSubtitle,
    KCrudLayout,
  },
  data() {
    return {
      orderDetails: new OrderDetail(),
      isPending: false,
      isPendingSubmit: false,
      isCommentsSidebarOpen: false,
      isRejectDialogOpen: false,
    };
  },
  watch: {
    async currentStoreId() {
      this.$router.push({ name: 'approvals.index' });
    },
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
    crudHeaders() {
      return [
        {
          value: 'image',
          text: '',
          width: 120,
        },
        {
          value: 'name',
          text: this.$t('shoppingCart.headers.itemName'),
          cellClass: 'py-3',
        },
        {
          value: 'sku',
          text: this.$t('shoppingCart.headers.itemCode'),
          cellClass: 'py-3',
        },
        {
          value: 'quantity',
          text: this.$t('shoppingCart.headers.quantity'),
          cellClass: 'py-3',
        },
        {
          value: 'price',
          text: this.$t('shoppingCart.headers.salesPrice'),
          cellClass: 'py-3',
        },
        {
          value: 'linePrice',
          text: this.$t('shoppingCart.headers.linePrice'),
          cellClass: 'py-3',
        },
      ];
    },
    approvalId() {
      return this.$route.params.approvalId;
    },
    totalAmount() {
      return this.orderDetails?.amount || 0;
    },
    approvers() {
      return this.orderDetails.approvers;
    },
    isCurrentUserNextApprover() {
      return this.orderDetails.approvers.find(({ isApproved }) => isApproved === null)?.user?.id || false;
    },
    ...mapState({ currentUserId: (state) => state.authorisation.profile.id }),
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const { data: { data } } = await show(to.params.approvalId);
      next((vm) => {
        vm.setOrderDetails(data);
      });
    } catch (e) {
      EventBus.$emit('snackbar', {
        color: 'error',
        text: i18n.t('errors.defaultErrorWrapper', [i18n.t('errors.fetchOrderData')]),
      });
      next();
    }
  },
  methods: {
    hasAddOns(item) {
      return item.addOns.products.length > 0;
    },
    async approve() {
      const approved = true;
      try {
        await this.reviewOrder(approved);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.approveOrder')]),
        });
      }

      setTimeout(async () => await this.indexRequest(), 250);
    },
    rejectHandler() {
      this.isRejectDialogOpen = true;
    },
    async reject(comment) {
      this.isRejectDialogOpen = false;
      const rejected = false;
      try {
        await this.reviewOrder(rejected, comment);
        await this.$router.push({ name: 'home' });
      } catch (e) {

        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.approveOrder')]),
        });
      }
    },
    async reviewOrder(isApproved, comment) {
      this.isPendingSubmit = true;
      await approve(this.approvalId, isApproved, comment);

      setTimeout(() => this.isPendingSubmit = false, 250);
    },
    setOrderDetails(data) {
      this.orderDetails = new OrderDetail().mapResponse(data);
    },
    async indexRequest(...args) {
      this.isPending = true;
      try {
        const { data: { data } } = await show(this.$route.params.approvalId, ...args);
        this.setOrderDetails(data);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.fetchOrderData')]),
        });
      }
      this.isPending = false;
      return {
        data: {
          data: this.orderDetails.orderLines,
        },
      };
    },
    approvalIcon(isApproved) {
      if (isApproved === null) return '$unknown';
      if (isApproved === true) return '$approved';
      return '$denied';
    },
    approvalIconColor(isApproved) {
      if (isApproved === null) return 'gray';
      if (isApproved === true) return 'green';
      return 'red';
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    // By default, the values within a td are aligned in the center/middle. Design wants the content to be
    // aligned top, with a padding.
    vertical-align: top;
    padding-top:    8px
  }
}

::v-deep {
  .v-data-table-header > tr > th {
    white-space: nowrap;
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    vertical-align: top;
    padding-top:    8px
  }

  .v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
    background-color: inherit;
  }

  .approver-created-at {
    color:     rgba(0, 0, 0, 0.6);
    font-size: 0.75em;
    display:   contents;
  }

  tbody tr:not(:first-child) td .custom-top-border {
    position: absolute;
    top: -1px;
    height: 1px;
    background: rgba(0, 0, 0, 0.12);
    left: -1px;
    right: -1px;
  }
}

</style>
